import { useContext, useEffect, useRef, useState } from "react";
import classNames from "clsx";

// temporary images for copper alive and alkaline alive

import godnessOfCopper from "@src/vitehomepageassets/images/plan-product/oldImages/lp114_butt0_product2.webp";
import UVPurification from "@src/vitehomepageassets/images/plan-product/oldImages/lp114_butt0_product3.webp";
import multistagePurificationCopper from "@src/vitehomepageassets/images/plan-product/oldImages/lp114_butt0_product1.webp";
import retainMinerals from "@src/vitehomepageassets/images/plan-product/oldImages/lp114_butt0_product6.webp";

import alkalineBoost from "@src/vitehomepageassets/images/plan-product/oldImages/alkaline_product_feature_img2.webp";
import multistagePurificationAlkaline from "@src/vitehomepageassets/images/plan-product/oldImages/alkaline_product_feature_img4.webp";
// *********************************************************

import lp114_butt0_prod1 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product1.webp";
import lp114_butt0_prod2 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product2.webp";
import lp114_butt0_prod3 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product3.webp";
import lp114_butt0_prod4 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product4.webp";
import lp114_butt0_prod5 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product5.webp";
import lp114_butt0_prod6 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product6.webp";
import lp114_butt0_prod7 from "../../vitehomepageassets/images/plan-product/lp114_butt0_product7.webp";

import lp114_butt1_prod1 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product4.webp";
import lp114_butt1_prod2 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product2.webp";
import lp114_butt1_prod3 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product3.webp";
import lp114_butt1_prod4 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product1.webp";
import lp114_butt1_prod5 from "../../vitehomepageassets/images/plan-product/lp114_butt1_product5.webp";

import alkaline_featureImg1 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img1.webp";
import alkaline_featureImg2 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img2.webp";
import alkaline_featureImg3 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img3.webp";
import alkaline_featureImg4 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img4.webp";
import alkaline_featureImg5 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img5.webp";
import alkaline_featureImg6 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img6.webp";
import alkaline_featureImg7 from "../../vitehomepageassets/images/plan-product/alkaline_product_feature_img7.webp";
import { Icons } from "../../components/icons";
import { CityPopupContext } from "../../pages/homepage";
import isAssetColorThemeDark from "../header/cityPopup.tsx/utils";

const copperAndAlkalineAliveAlt = {
  "0": [
    "8 Litre Storage for your DrinkPrime Water Purifier",
    "Best RO UV Copper water purifier with godness of copper",
    "Best RO UV Copper water purifier with powerful UV Purification",
    "DrinkPrime RO UV Water Purifier with Multistage Purification System",
    "Best RO UV water purifier which retains minerals",
  ],
  "2": [
    "8 Litre Storage for your DrinkPrime Water Purifier",
    "Best RO UV Alkaline water purifier with alkaline boost",
    "Best RO UV Alkaline water purifier with powerful UV Purification",
    "DrinkPrime RO UV Water Purifier with Multistage Purification System",
  ],
};

const altText = [
  [
    "8 Litre Storage for your DrinkPrime Water Purifier",
    "Best RO UV Copper Water purifer available on Rent",
    "RO UV Copper water purifier with 15 LPH purification capacity",
    "Best RO UV Copper water purifier with powerful in-Line UV Purification",
    "DrinkPrime RO UV Water Purifier with Multistage Purification System",
    "Best RO UV water purifier with Copper filter",
    "Best RO UV Copper water purifier with wall mount",
  ],
  [
    "8 Litre Storage for your DrinkPrime Water Purifier",
    "Best Partical remover - Water Purifier on Rent",
    "Best Odour Remover - Water Purifier on Rent",
    "Best Ro Membrane - Water Purifier on Rent",
    "Real time monitoring through IOT Technology",
  ],
  [
    "8 Litre Storage for your DrinkPrime Water Purifier",
    "Drinkprime RO UV water purifier with alkaline boost",
    "Best RO UV Alkaline water purifier with 15 LPH purification capacity",
    "DrinkPrime RO UV Water Purifier with Multistage Purification System",
    "Best RO UV Alkaline water purifier with powerful in-Line UV Purification",
    "Best RO UV water purifier with Alkaline filter",
    "Best RO UV Alkaline water purifier available on rent",
  ],
];

export const ProductImagesCarousel = ({
  buttonIndex = 0,
}: {
  buttonIndex?: 0 | 1 | 2;
}) => {
  const { cityPopupSelection } = useContext(CityPopupContext);
  let isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);

  useEffect(() => {
    isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);
  }, [cityPopupSelection]);
  const imagesLp114_3d = [
    [
      isShownDarkAsset ? lp114_butt0_prod2 : lp114_butt0_prod1,
      isShownDarkAsset ? lp114_butt0_prod4 : godnessOfCopper,
      isShownDarkAsset ? lp114_butt0_prod5 : UVPurification,
      isShownDarkAsset ? lp114_butt0_prod6 : multistagePurificationCopper,
    ],
    [
      lp114_butt1_prod1,
      lp114_butt1_prod2,
      lp114_butt1_prod3,
      lp114_butt1_prod4,
      lp114_butt1_prod5,
    ],
    [
      isShownDarkAsset ? alkaline_featureImg7 : alkaline_featureImg1,
      isShownDarkAsset ? alkaline_featureImg2 : alkalineBoost,
      isShownDarkAsset ? alkaline_featureImg4 : UVPurification,
      isShownDarkAsset ? alkaline_featureImg5 : multistagePurificationAlkaline,
      alkaline_featureImg6,
    ],
  ];
  if (isShownDarkAsset) {
    imagesLp114_3d[0].push(...[lp114_butt0_prod7, lp114_butt0_prod3]);
    imagesLp114_3d[2].push(alkaline_featureImg3);
  } else {
    imagesLp114_3d[0].push(retainMinerals);
    imagesLp114_3d[2].pop();
  }
  const imagesLp114 = imagesLp114_3d[buttonIndex];
  const images = imagesLp114;

  const altOrder = {
    0: {
      true: [1, 3, 4, 5, 6, 2],
      false: [0, 3, 4, 5],
    },
    1: {
      true: [0, 1, 2, 3, 4],
      false: [0, 1, 2, 3, 4],
    },
    2: {
      true: [2, 1, 3, 4, 5, 6],
      false: [0, 1, 3, 4, 5],
    },
  } as const;
  let alt =
    buttonIndex in altOrder
      ? altOrder[buttonIndex][isShownDarkAsset === true ? "true" : "false"].map(
          (elm) => altText[buttonIndex][elm]
        )
      : altText[buttonIndex];

  // *****************
  if (!isShownDarkAsset && (buttonIndex === 0 || buttonIndex === 2)) {
    alt = copperAndAlkalineAliveAlt[buttonIndex];
  }

  const [activeImageNo, setActiveImageNo] = useState(0);
  const ref = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (ref.current) ref.current.scrollLeft = activeImageNo * 74;
  }, [activeImageNo]);
  useEffect(() => {
    setActiveImageNo(0);
    const intervalPointer = setInterval(
      () => setActiveImageNo((n) => (n + 1) % images.length),
      500000
    );
    return () => clearInterval(intervalPointer);
  }, [buttonIndex, images.length]);
  return (
    <div className="flex flex-col gap-4 max-w-[465px] w-full">
      <div
        data-component="tab-content"
        className="overflow-hidden h-[min(calc(100vw-30px),465px)]"
      >
        {/* last class is because between the transition - height becomes zero and scroll snaps */}
        <img
          src={images[activeImageNo]}
          alt={alt[activeImageNo]}
          className={classNames(
            "w-full rounded-2xl",
            "animate-slideTextFromRightAnimation"
          )}
          key={activeImageNo}
          loading="lazy"
        />
      </div>
      <div className="flex gap-3 justify-center">
        <div
          className="flex items-center w-[24px] flex-shrink-0 "
          onClick={() =>
            setActiveImageNo((prev) =>
              prev == 0 ? images.length - 1 : prev - 1
            )
          }
        >
          {/* <img src={arrow_left} alt="left-arrow" className="w-5 h-5" /> */}
          <Icons name="left-arrow" className="w-5 h-5" />
        </div>
        <ul
          ref={ref}
          data-component="tab-link"
          className={classNames(
            "flex gap-2.5 overflow-auto nth-n:flex-shrink-0",
            "py-2 px-1",
            "no-scrollbar"
          )}
        >
          {images.map((image, index) => (
            <li key={index}>
              <img
                src={image}
                alt={alt[index]}
                onClick={() => setActiveImageNo(index)}
                className={classNames(
                  "w-[64px] lg:w-[68px] rounded-lg cursor-pointer",
                  index == activeImageNo &&
                    "scale-110 border-2 border-primary-primary"
                )}
              />
            </li>
          ))}
        </ul>
        <div
          className="flex items-center w-[24px] flex-shrink-0"
          onClick={() =>
            setActiveImageNo((prev) =>
              prev == images.length - 1 ? 0 : prev + 1
            )
          }
        >
          <Icons name="left-arrow" className="w-5 h-5 rotate-180" />
        </div>
      </div>
    </div>
  );
};
