import classNames from "clsx";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import { Images } from "@src/components/images";
import { useContext, useEffect } from "react";
import { CityPopupContext } from "../../pages/homepage";
import isAssetColorThemeDark from "../header/cityPopup.tsx/utils";
import { cn } from "../../lib/utils";

function HeroSection() {
  const { cityPopupSelection } = useContext(CityPopupContext);
  let isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);
  useEffect(() => {
    isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);
  }, [cityPopupSelection]);
  return (
    <section
      className={classNames(
        "p-4 xl:pl-[60px] flex lg:items-center",
        "hero-section relative",
        "h-[178vw] [@media(min-width:300px)]:-lg:h-[160vw]  bg-cover bg-bottom",
        "lg:bg-cover lg:h-[500px] bg-no-repeat lg:bg-right-top 2xl:bg-right "
      )}
    >
      <Images
        isBgImg={true}
        loading="eager"
        name={
          isShownDarkAsset
            ? "hero/otherCities-desktop"
            : "hero/bengaluru-desktop"
        }
        className="hidden lg:block"
        alt="testimonials background"
      />
      <Images
        isBgImg={true}
        loading="eager"
        name={
          isShownDarkAsset
            ? "hero/otherCities-mobile"
            : "hero/bengaluru-mobile"
        }
        className={cn(
          "lg:hidden object-[0_20px] xs:object-top",
        )}
        alt="testimonials background"
      />
      {/* text container */}
      <div className="-lg:text-center -lg:mx-auto w-full lg:w-1/4 xl:w-1/3">
        <h2 className="text-primary-primary text-[32px] leading-[130%] font-bold sm:text-[36px] xl:text-[56px] md:leading-[150%]">
          DrinkPrime
        </h2>
        <h1 className="text-titleActive leading-[130%] font-semibold sm:text-xl xl:text-[24px]">
          Smart Purifiers on Rent.
          <br />
          Free Maintenance for Life.
        </h1>
        <p className="mt-2 sm:mt-[15px] text-titleActive text-[14px] sm:text-lg xl:text-[20px] leading:[140%] md:leading-[150%]">
          IoT-enabled RO+UV water purifiers with Copper Filter, Alkaline Filter,
          & Mineraliser.
          <span className="font-bold text-primary-primary inline-block">
            Pay only rentals and get lifetime free maintenance. ZERO machine
            cost.
          </span>
        </p>
        <LeadFormPopupTriggerButton
          className="mt-3 sm:mt-4 w-[240px] h-10 sm:h-12 flex justify-center items-center bg-primary-primary text-white rounded-full -lg:mx-auto"
          nextRoute={(params) => {
            window.location.href =
              "https://drinkprime.in/app/checkout/chooseplan?" + params;
          }}
          formName="homepage/hero-form"
        >
          BOOK NOW
        </LeadFormPopupTriggerButton>
      </div>
    </section>
  );
}

export default HeroSection;
