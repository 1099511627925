import { useContext, useEffect } from "react";
import { cn, scrollToId } from "../../lib/utils";
import { CityPopupContext } from "../../pages/homepage";
import smartwaterSubscription from "../../vitehomepageassets/images/smartwater-subscription/smartwater-subscription.webp";
import smartwaterSubscription_copperStorm from "../../vitehomepageassets/images/smartwater-subscription/copper-storm.webp";
import LeadFormPopupTriggerButton from "../LeadFormSection/LeadFormPopup/LeadFormPopupTriggerButton";
import isAssetColorThemeDark from "../header/cityPopup.tsx/utils";

function SmartWaterOnSubscription() {
  const { cityPopupSelection } = useContext(CityPopupContext);
  let isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);
  useEffect(() => {
    isShownDarkAsset = isAssetColorThemeDark(cityPopupSelection);
  }, [cityPopupSelection]);
  return (
    <section className="py-[40px] px-4 sm:px-10 lg:py-[70px] overflow-hidden">
      {/* container */}
      <div className="md:flex md:gap-6 xl:gap-20 md:items-center max-w-[1250px] md:mx-auto">
        <SectionHeadings />
        <div
          className={cn(
            "md:max-w-[500px] md:min-w-[400px] md:flex-1 mt-5",
            isShownDarkAsset && "lg:scale-[1.3] "
          )}
        >
          <img
            src={
              isShownDarkAsset
                ? smartwaterSubscription_copperStorm
                : smartwaterSubscription
            }
            alt="Best RO+UV Water Purifier on Rent - Starting at Just ₹333*/month"
          />
        </div>
        <ButtonGroup className="md:hidden" />
      </div>
    </section>
  );
}

export default SmartWaterOnSubscription;

function SectionHeadings() {
  return (
    <div className="md:flex-1 md:max-w-[54%]">
      <h2 className="text-primary-primary text-2xl font-bold sm:text-5xl xl:text-[64px] leading-[34px] xl:leading-[130%]">
        Smart RO Water Purifiers <br className="md:hidden" />
        <span className="text-titleActive -sm:text-nowrap">
          On Subscription
        </span>
      </h2>
      <p className="text-body text-sm sm:text-lg xl:text-xl mt-3 leading-[21px]">
        Tired of running out of water cans in the middle of the night? Or
        burning a hole in your pocket trying to maintain your water purifier?
        <br />
        <span className="font-bold md:font-semibold text-primary-primary mt-2 inline-block">
          Switch to DrinkPrime's IoT-enabled smart water purifiers on
          subscription, by choosing a rental plan for 28, 90 or 360 days.
          <br />
          <span className="mt-1 lg:mt-2 inline-block">
            Get lifetime free maintenance with zero machine cost - pure water,
            hassle-free!
          </span>
        </span>
      </p>
      <ButtonGroup className="-md:hidden" />
    </div>
  );
}

function ButtonGroup({ className }: { className?: string }) {
  const baseClasses = {
    subscribe:
      "bg-primary-primary text-off-white rounded-full py-4 font-semibold ",
    "how-it-works":
      "outline-[2px] outline outline-primary-primary text-primary-primary rounded-full py-4 font-semibold ",
  };
  return (
    <div
      className={cn(
        "mt-4 flex flex-col md:flex-row gap-3 lg:gap-6 md:nth-n:flex-1 items-center",
        className
      )}
    >
      <LeadFormPopupTriggerButton
        className={cn(
          baseClasses["subscribe"] +
            "w-[320px] max-w-[500px] text-sm md:text-[17px]"
        )}
        nextRoute={(params) => {
          window.location.href =
            "https://drinkprime.in/app/checkout/chooseplan?" + params;
        }}
        formName="homepage/smart-ro-purifier"
      >
        Subscribe Now
      </LeadFormPopupTriggerButton>
      <button
        type="button"
        className={cn(
          baseClasses["how-it-works"] +
            "w-[320px] max-w-[500px] text-sm md:text-[17px]"
        )}
        onClick={() => {
          scrollToId("how-it-works-section");
        }}
      >
        Know How It Works
      </button>
    </div>
  );
}
