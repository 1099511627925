import { getUTMParams, pushDataLayer } from "../../../lib/dataLayer";
import { setItemInLocalStorage } from "../../../lib/plans";
import { getFromLocalVisitObject } from "../../../lib/urlLocalServices";
import { trackRevisitType } from "../../../lib/visitObject";
import { LeadFormData } from "./utils";
import { getBodyForLeadsquare } from "./getBodyForLeadsquare";
import { getCookie, getPhoneHash } from "./utils";

export async function handleLeadFormSubmission(
  data: LeadFormData,
  form_name: string
) {
  console.log("values:", data);
  //unavailable: http://localhost:5173/app/checkout/chooseplan?name=name1&mobile=9876543210&city=Others&otherCity=tumkur&coupon=coupon12
  //address: http://localhost:5173/app/checkout/chooseplan?name=name1&mobile=9876543210&city=Bengaluru&coupon=coupon12
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(getBodyForLeadsquare(data)),
  };
  const phoneHash = getPhoneHash("91" + data["mobile"]);
  try {
    const response = await fetch(
      `https://api.drinkprime.in/website/leadSquared`,
      options
    );
    if (response.ok) {
      setItemInLocalStorage("selected-city-popup", {
        city: data.city,
        modified_date: String(new Date()),
      });

      setItemInLocalStorage("lead-details", data);
      const {
        ...userDetails
      }: { mobile: string; coupon?: string } & trackRevisitType = {
        ...data,
        phone: data["mobile"],
        createdDate: new Date(),
        revisitDate: new Date(),
        user_id: `DP-${data["mobile"]}`,
      };

      setItemInLocalStorage("trackRevisit", userDetails);
      const leadData = {
        form_name,
        user_phone: data["mobile"],
        user_name: data["name"],
        user_email: null,
        user_city: data["city"],
        user_custom_city: data?.otherCity || "",
        user_id: "DP-91" + data["mobile"],
        utm_campaign:
          getFromLocalVisitObject("session_visit").utm_campaign ||
          getUTMParams().utm_campaign ||
          null,
          utm_medium:
          getFromLocalVisitObject("session_visit").utm_medium ||
          getUTMParams().utm_medium ||
          null,
        utm_source:
          getFromLocalVisitObject("session_visit").utm_source ||
          getUTMParams().utm_source ||
          null,  
        referral_code: data["coupon"] || "",
        dp_fbc: getCookie("_fbc"),
        dp_ua: window.navigator.userAgent,
        dp_phonehash: await phoneHash,
      };
      for (const [key, value] of Object.entries(leadData)) {
        if (!value) delete leadData[key as keyof typeof leadData];
      }
      // @ts-expect-error no overload match
      pushDataLayer("w_leadform_submit_clicked", leadData, {
        isFormSubmission: true,
      });
      if (data.otherCity) return "unavailablePage";
      else return "addressPage";
    } else throw new Error("response is not ok");
  } catch (e) {
    console.error(`Api fetching failed with error:${e}`);
    return "unavailablePage";
  }
}
